exports.components = {
  "component---src-pages-3-simple-steps-secure-mongo-db-aws-jsx": () => import("./../../../src/pages/3-simple-steps-secure-mongo-db-aws.jsx" /* webpackChunkName: "component---src-pages-3-simple-steps-secure-mongo-db-aws-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-add-scale-iot-framework-aws-serverless-lambda-dynamo-jsx": () => import("./../../../src/pages/add-scale-iot-framework-aws-serverless-lambda-dynamo.jsx" /* webpackChunkName: "component---src-pages-add-scale-iot-framework-aws-serverless-lambda-dynamo-jsx" */),
  "component---src-pages-amazon-lex-bot-facebook-integration-send-api-jsx": () => import("./../../../src/pages/amazon-lex-bot-facebook-integration-send-api.jsx" /* webpackChunkName: "component---src-pages-amazon-lex-bot-facebook-integration-send-api-jsx" */),
  "component---src-pages-chatbot-and-iot-jsx": () => import("./../../../src/pages/chatbot-and-iot.jsx" /* webpackChunkName: "component---src-pages-chatbot-and-iot-jsx" */),
  "component---src-pages-choose-right-gps-system-jsx": () => import("./../../../src/pages/choose-right-gps-system.jsx" /* webpackChunkName: "component---src-pages-choose-right-gps-system-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-creating-your-own-license-plate-recognition-system-in-python-jsx": () => import("./../../../src/pages/creating-your-own-license-plate-recognition-system-in-python.jsx" /* webpackChunkName: "component---src-pages-creating-your-own-license-plate-recognition-system-in-python-jsx" */),
  "component---src-pages-detect-vehicles-driving-neutral-gear-iott-jsx": () => import("./../../../src/pages/detect-vehicles-driving-neutral-gear-iott.jsx" /* webpackChunkName: "component---src-pages-detect-vehicles-driving-neutral-gear-iott-jsx" */),
  "component---src-pages-digitise-mining-iot-ai-jsx": () => import("./../../../src/pages/digitise-mining-iot-ai.jsx" /* webpackChunkName: "component---src-pages-digitise-mining-iot-ai-jsx" */),
  "component---src-pages-gps-software-as-a-service-jsx": () => import("./../../../src/pages/gps-software-as-a-service.jsx" /* webpackChunkName: "component---src-pages-gps-software-as-a-service-jsx" */),
  "component---src-pages-how-does-gps-work-jsx": () => import("./../../../src/pages/how-does-gps-work.jsx" /* webpackChunkName: "component---src-pages-how-does-gps-work-jsx" */),
  "component---src-pages-how-to-install-osrm-and-nominatim-on-ubuntu-16-02-google-maps-alternative-jsx": () => import("./../../../src/pages/how-to-install-osrm-and-nominatim-on-ubuntu-16-02-google-maps-alternative.jsx" /* webpackChunkName: "component---src-pages-how-to-install-osrm-and-nominatim-on-ubuntu-16-02-google-maps-alternative-jsx" */),
  "component---src-pages-how-to-make-report-in-power-bi-js": () => import("./../../../src/pages/how-to-make-report-in-power-bi.js" /* webpackChunkName: "component---src-pages-how-to-make-report-in-power-bi-js" */),
  "component---src-pages-how-to-make-report-in-power-bi-part-2-js": () => import("./../../../src/pages/how-to-make-report-in-power-bi-part2.js" /* webpackChunkName: "component---src-pages-how-to-make-report-in-power-bi-part-2-js" */),
  "component---src-pages-how-to-measure-clutch-riding-with-obd-iot-device-jsx": () => import("./../../../src/pages/how-to-measure-clutch-riding-with-obd-iot-device.jsx" /* webpackChunkName: "component---src-pages-how-to-measure-clutch-riding-with-obd-iot-device-jsx" */),
  "component---src-pages-how-to-setup-a-cctv-camera-with-jiofi-jsx": () => import("./../../../src/pages/how-to-setup-a-cctv-camera-with-jiofi.jsx" /* webpackChunkName: "component---src-pages-how-to-setup-a-cctv-camera-with-jiofi-jsx" */),
  "component---src-pages-how-to-setup-cctv-camera-with-xmeye-tool-set-jsx": () => import("./../../../src/pages/how-to-setup-cctv-camera-with-xmeye-tool-set.jsx" /* webpackChunkName: "component---src-pages-how-to-setup-cctv-camera-with-xmeye-tool-set-jsx" */),
  "component---src-pages-how-to-setup-ci-cd-for-react-using-jenkins-and-docker-on-awss-3-jsx": () => import("./../../../src/pages/how-to-setup-ci-cd-for-react-using-jenkins-and-docker-on-awss3.jsx" /* webpackChunkName: "component---src-pages-how-to-setup-ci-cd-for-react-using-jenkins-and-docker-on-awss-3-jsx" */),
  "component---src-pages-how-to-setup-ftp-on-aws-ubuntu-jsx": () => import("./../../../src/pages/how-to-setup-ftp-on-aws-ubuntu.jsx" /* webpackChunkName: "component---src-pages-how-to-setup-ftp-on-aws-ubuntu-jsx" */),
  "component---src-pages-how-to-setup-secure-emqx-mqtt-on-ubuntu-18-jsx": () => import("./../../../src/pages/how-to-setup-secure-emqx-mqtt-on-ubuntu-18.jsx" /* webpackChunkName: "component---src-pages-how-to-setup-secure-emqx-mqtt-on-ubuntu-18-jsx" */),
  "component---src-pages-impact-of-aggressive-driving-on-fuel-efficiency-with-obd-jsx": () => import("./../../../src/pages/impact-of-aggressive-driving-on-fuel-efficiency-with-obd.jsx" /* webpackChunkName: "component---src-pages-impact-of-aggressive-driving-on-fuel-efficiency-with-obd-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-secure-robust-mosquitto-mqtt-broker-aws-ubuntu-jsx": () => import("./../../../src/pages/install-secure-robust-mosquitto-mqtt-broker-aws-ubuntu.jsx" /* webpackChunkName: "component---src-pages-install-secure-robust-mosquitto-mqtt-broker-aws-ubuntu-jsx" */),
  "component---src-pages-install-secure-robust-redis-aws-ubuntu-jsx": () => import("./../../../src/pages/install-secure-robust-redis-aws-ubuntu.jsx" /* webpackChunkName: "component---src-pages-install-secure-robust-redis-aws-ubuntu-jsx" */),
  "component---src-pages-iot-second-hand-car-evaluation-jsx": () => import("./../../../src/pages/iot-second-hand-car-evaluation.jsx" /* webpackChunkName: "component---src-pages-iot-second-hand-car-evaluation-jsx" */),
  "component---src-pages-launch-spot-fleet-jsx": () => import("./../../../src/pages/launch-spot-fleet.jsx" /* webpackChunkName: "component---src-pages-launch-spot-fleet-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-products-electric-vehicle-monitoring-jsx": () => import("./../../../src/pages/products/electric-vehicle-monitoring.jsx" /* webpackChunkName: "component---src-pages-products-electric-vehicle-monitoring-jsx" */),
  "component---src-pages-products-freight-management-jsx": () => import("./../../../src/pages/products/freight-management.jsx" /* webpackChunkName: "component---src-pages-products-freight-management-jsx" */),
  "component---src-pages-products-fuel-tracking-jsx": () => import("./../../../src/pages/products/fuel-tracking.jsx" /* webpackChunkName: "component---src-pages-products-fuel-tracking-jsx" */),
  "component---src-pages-products-insurance-banking-jsx": () => import("./../../../src/pages/products/insurance-banking.jsx" /* webpackChunkName: "component---src-pages-products-insurance-banking-jsx" */),
  "component---src-pages-products-karnataka-ais-140-jsx": () => import("./../../../src/pages/products/karnataka-ais140.jsx" /* webpackChunkName: "component---src-pages-products-karnataka-ais-140-jsx" */),
  "component---src-pages-products-mining-ais-140-jsx": () => import("./../../../src/pages/products/mining-ais140.jsx" /* webpackChunkName: "component---src-pages-products-mining-ais-140-jsx" */),
  "component---src-pages-products-mining-solutions-jsx": () => import("./../../../src/pages/products/mining-solutions.jsx" /* webpackChunkName: "component---src-pages-products-mining-solutions-jsx" */),
  "component---src-pages-products-mobile-apps-jsx": () => import("./../../../src/pages/products/mobile-apps.jsx" /* webpackChunkName: "component---src-pages-products-mobile-apps-jsx" */),
  "component---src-pages-products-obd-solutions-jsx": () => import("./../../../src/pages/products/obd-solutions.jsx" /* webpackChunkName: "component---src-pages-products-obd-solutions-jsx" */),
  "component---src-pages-products-reefer-solutions-jsx": () => import("./../../../src/pages/products/reefer-solutions.jsx" /* webpackChunkName: "component---src-pages-products-reefer-solutions-jsx" */),
  "component---src-pages-products-school-bus-gps-tracking-jsx": () => import("./../../../src/pages/products/school-bus-gps-tracking.jsx" /* webpackChunkName: "component---src-pages-products-school-bus-gps-tracking-jsx" */),
  "component---src-pages-products-tpms-tyre-pressure-monitoring-system-jsx": () => import("./../../../src/pages/products/tpms-tyre-pressure-monitoring-system.jsx" /* webpackChunkName: "component---src-pages-products-tpms-tyre-pressure-monitoring-system-jsx" */),
  "component---src-pages-products-video-analytics-jsx": () => import("./../../../src/pages/products/video-analytics.jsx" /* webpackChunkName: "component---src-pages-products-video-analytics-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-return-jsx": () => import("./../../../src/pages/return.jsx" /* webpackChunkName: "component---src-pages-return-jsx" */),
  "component---src-pages-scalable-iot-platform-mean-stack-jsx": () => import("./../../../src/pages/scalable-iot-platform-mean-stack.jsx" /* webpackChunkName: "component---src-pages-scalable-iot-platform-mean-stack-jsx" */),
  "component---src-pages-setup-osm-tile-server-ubuntu-16-02-googlle-maps-tile-server-alternative-jsx": () => import("./../../../src/pages/setup-osm-tile-server-ubuntu-16-02-googlle-maps-tile-server-alternative.jsx" /* webpackChunkName: "component---src-pages-setup-osm-tile-server-ubuntu-16-02-googlle-maps-tile-server-alternative-jsx" */),
  "component---src-pages-speed-gears-and-fuel-economyy-jsx": () => import("./../../../src/pages/speed-gears-and-fuel-economyy.jsx" /* webpackChunkName: "component---src-pages-speed-gears-and-fuel-economyy-jsx" */),
  "component---src-pages-supported-devices-jsx": () => import("./../../../src/pages/supported-devices.jsx" /* webpackChunkName: "component---src-pages-supported-devices-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

